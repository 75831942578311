// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => (
  <>
    <Header />
    <div id="main" role="main" className="main">
  <section
    className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-5 mb-0"
    style={{
      backgroundImage: 'url("/files/bg1.webp")',
      backgroundPosition: "center center"
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-12 align-self-center p-static order-2 text-center">
          <h1 className="text-8 font-weight-semi-bold text-uppercase">
            Politika e Privatësisë
          </h1>
        </div>
      </div>
    </div>
  </section>
  <div className="container py-5 my-3">
    <div className="col-12 mb-5">
      <div className="d-flex flex-wrap justify-content-between mb-3">
        <h2 className="mb-0" style={{ fontWeight: 600 }} />
      </div>
      <div className="children-img-fluid">
        <p>
          Faleminderit që vizituat Live by Travels. Kjo Politikë e Privatësisë
          përshkruan mënyrën se si mbledhim, përdorim dhe mbrojmë informacionin
          tuaj personal kur përdorni faqen tonë të internetit dhe shërbimet
          tona.
        </p>
        <h2>1. Informacioni që mbledhim</h2>
        <p>
          Ne mund të mbledhim informacion të ndryshëm kur vizitoni faqen tonë,
          duke përfshirë:
        </p>
        <ul>
          <li>
            Informacionin personal si emri, email-i, numri i telefonit, kur
            regjistroheni për të përdorur shërbimet tona ose për të kontaktuar
            me ne.
          </li>
          <li>
            Informacion teknik si adresa IP, lloji i shfletuesit, dhe të dhëna
            të tjera të ngjashme për të përmirësuar përvojën tuaj të përdorimit
            të faqes sonë.
          </li>
        </ul>
        <h2>2. Si e përdorim informacionin tuaj</h2>
        <p>Informacioni juaj përdoret për qëllime të ndryshme, përfshirë:</p>
        <ul>
          <li>Për të ofruar dhe përmirësuar shërbimet tona;</li>
          <li>Për të personalizuar përvojën tuaj në faqen tonë;</li>
          <li>
            Për t’ju dërguar njoftime, oferta ose informacione të tjera të
            rëndësishme;
          </li>
          <li>
            Për të përmbushur kërkesat ligjore dhe rregulloret e zbatueshme.
          </li>
        </ul>
        <h2>3. Siguria e të dhënave</h2>
        <p>
          Ne ndërmarrim masa të përshtatshme për të mbrojtur informacionin tuaj
          personal nga qasjet e paautorizuara, ndryshimet ose shkatërrimi i tij.
          Megjithatë, asnjë metodë e transmetimit përmes internetit ose metodë e
          ruajtjes elektronike nuk është 100% e sigurt, dhe ne nuk mund të
          garantojmë sigurinë absolute të informacionit tuaj.
        </p>
        <h2>4. Të drejtat tuaja</h2>
        <p>
          Ju keni të drejtë të aksesoni, korrigjoni ose fshini informacionin
          tuaj personal që ne mbajmë. Ju gjithashtu mund të tërhiqni pëlqimin
          tuaj për përpunimin e të dhënave në çdo kohë duke na kontaktuar përmes
          informacioneve të kontaktit të dhëna më poshtë.
        </p>
        <h2>5. Ndryshimet në Politikën e Privatësisë</h2>
        <p>
          Ne mund të përditësojmë këtë Politikë të Privatësisë herë pas here.
          Çdo ndryshim do të publikohet në këtë faqe dhe data e përditësimit do
          të reflektohet në krye të politikës.
        </p>
        <h2>6. Kontakt</h2>
        <p>
          Nëse keni ndonjë pyetje ose kërkesë lidhur me këtë Politikë të
          Privatësisë, ju lutemi na kontaktoni në:
        </p>
        <p>
          Email:{" "}
          <a href="mailto:support@livebytravels.com">
            support@livebytravels.com
          </a>
        </p>
      </div>
    </div>
  </div>
</div>


    <Footer />
  </>
);

export default Home;
