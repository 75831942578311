// src/pages/Home.js
import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/Contact.css';

const Contact = () => {
    const [showThankYou, setShowThankYou] = useState(false);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setShowThankYou(true);
    };

    return (
        <>
            {/* Render Header component at the top */}
            <Header />

            <main id="main" role="main" className="main">
                <section
                    className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-5 mb-0"
                    style={{ backgroundImage: `url('/files/bg1.webp')`, backgroundPosition: 'center center' }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 align-self-center p-static order-2 text-center">
                                <h1 className="text-8 font-weight-semi-bold text-uppercase">Kontakt</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container py-5 my-3">
                    <div className="col-12 mb-5">
                        <div className="d-flex flex-wrap justify-content-between mb-3">
                            <h2 className="mb-0" style={{ fontWeight: 600 }}></h2>
                        </div>

                        <div className="children-img-fluid">
                            <h1>Na Kontaktoni</h1>
                            <p>Ju lutemi, plotësoni formularin më poshtë dhe do të ju përgjigjemi sa më shpejt të jetë e mundur.</p>

                            <form onSubmit={handleFormSubmit}>
                                <label htmlFor="name">Emri:</label>
                                <input type="text" id="name" name="name" required /><br />

                                <label htmlFor="email">Email-i:</label>
                                <input type="email" id="email" name="email" required /><br />

                                <label htmlFor="message">Mesazhi:</label>
                                <textarea id="message" name="message" rows="4" required></textarea><br />

                                <button type="submit">Dërgo</button>
                            </form>

                            {showThankYou && (
                                <p id="thankYouMessage" className="thank-you">
                                    Faleminderit që na kontaktuat! Do të ju përgjigjemi së shpejti.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Contact;
