import React, { useState, useEffect } from 'react';
import '../styles/CookieBox.css';
import { useNavigate } from 'react-router-dom';

const CookieBox = () => {
  const [visible, setVisible] = useState(true);

  const [showCookieBox, setShowCookieBox] = useState(true);

    const extractGclidFromURL = () => {
        const currentURL = window.location.href;
        if (currentURL.includes('?')) {
            const queryString = currentURL.split('?')[1];
            const params = new URLSearchParams(queryString);
            return params.get('gclid');
        }
        return null;
    };

    const finalGclid = extractGclidFromURL();

    const handleAcceptCookies = () => {
        if (finalGclid !== null) {
            window.location.href = `/page/?gclid=${finalGclid}`;
        } else {
            window.location.href = "/page/";
        }
    };

    const handleDeclineCookies = () => {
        window.location.href = "/privacy/";
    };

  if (!visible) return null;

  return (
    <div className="cookiebox">
      <style
  dangerouslySetInnerHTML={{ __html: "html, body{\n    overflow: hidden;\n}" }}
/>
      <div className="cookiebox-container">
        <h2>🍪 Ne përdorim cookies</h2>
        <p>Për të përmirësuar përvojën tuaj të shfletimit dhe për të analizuar trafikun e faqes sonë, përdorim cookies. Duke vazhduar përdorimin e kësaj faqeje, ju pranoni përdorimin tonë të cookies.</p>
        <div className="cookiebox-buttons">
          <button id="btnYes" onClick={handleAcceptCookies}>Po, pranoj</button>
          <button id="btnNo" onClick={handleDeclineCookies}>Jo, nuk pranoj</button>
        </div>
      </div>
    </div>
  );
};

export default CookieBox;
