import React, { useState } from 'react';
import '../styles/Header.css';
import '../styles/App.css';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const location = useLocation();

  if (location.pathname === '/page/' || location.pathname === '/page' || location.pathname === '/page/contact/' || location.pathname === '/page/contact' || location.pathname === '/page/privacy/' || location.pathname === '/page/privacy' || location.pathname === '/page/terms/' || location.pathname === '/page/terms') {
    return (
<header
  id="header"
  className="header-transparent header-semi-transparent header-semi-transparent-dark header-effect-shrink"
  style={{ height: 110 }}
>
  <div
    className="header-body header-effect-shrink border-top-0 "
    style={{ position: "fixed", top: 0 }}
  >
    <div
      className="header-container mx-3"
      style={{ height: 110, minHeight: 0 }}
    >
      <div className="header-row flex-column justify-content-center align-items-center">
        <div className="header-column justify-content-end">
          <div className="header-row justify-content-center flex-column flex-sm-row">
            <div className="header-logo m-0 pt-2 pt-sm-0">
            <Link to="/page">
                <img
                  width={321}
                  height={60}
                  className="img-fluid"
                  src="/files/logo.png"
                  style={{ top: 0, width: 250, height: 80 }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
    )
  }

  return (
    <header
  id="header"
  className="header-transparent header-semi-transparent header-semi-transparent-dark header-effect-shrink"
  style={{ height: 110 }}
>
  <div
    className="header-body header-effect-shrink border-top-0 "
    style={{ position: "fixed", top: 0 }}
  >
    <div
      className="header-container mx-3"
      style={{ height: 110, minHeight: 0 }}
    >
      <div className="header-row flex-column justify-content-center align-items-center">
        <div className="header-column justify-content-end">
          <div className="header-row justify-content-center flex-column flex-sm-row">
            <div className="header-logo m-0 pt-2 pt-sm-0">
            <Link to="/">
                <img
                  width={321}
                  height={60}
                  className="img-fluid"
                  src="/files/logo.png"
                  style={{ top: 0, width: 250, height: 80 }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
  )
}

export default Header;
