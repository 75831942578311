// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CookieBox from '../components/CookieBox';

const Home = () => (
  <>
    <CookieBox />
    <Header />
<div id="main" role="main" className="main">
  <section
    className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-5 mb-0"
    style={{
      backgroundImage: 'url("/files/bg1.webp")',
      backgroundPosition: "center center"
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-12 align-self-center p-static order-2 text-center">
          <h1 className="text-8 font-weight-semi-bold text-uppercase">
            Destinacionet Kryesore të Shqipërisë për Luks dhe Emocione
          </h1>
          <p style={{ color: "white", marginTop: 20 }}>
            Hyni në një botë elegance dhe emocion me destinacionet më të mira të
            Shqipërisë. Nga akomodimet luksoze te përvojat emocionuese,
            eksploroni një përzgjedhje destinacionesh ku sofistikimi takon
            aventurën.
          </p>
        </div>
      </div>
    </div>
  </section>
  <div className="container py-5 my-3">
    <div className="col-12 mb-5">
      <div className="d-flex flex-wrap justify-content-between mb-3">
        <h2 className="mb-0" style={{ fontWeight: 600 }} />
      </div>
      <div className="children-img-fluid">
        <h2>Rreth Nesh</h2>
        <p>
          Live by Travels është udhëzuesi juaj kryesor për qëndrimet luksoze dhe
          destinacionet ekskluzive më të mira në Shqipëri.{" "}
        </p>
        <p>
          Jemi të pasionuar për të treguar më të mirat që Shqipëria ka për të
          ofruar, nga akomodimet mbresëlënëse dhe përvojat unike të argëtimit,
          deri te shërbimet e klasit të parë dhe mikpritja e ngrohtë vendase.
          Misioni ynë është t’ju afrojmë me elegancën, emocionin dhe hijeshinë e
          veçantë që karakterizojnë arratisjet luksoze të Shqipërisë.{" "}
        </p>
        <p>
          Qoftë për një udhëtim të paharrueshëm apo për të eksploruar, jemi këtu
          për t'ju ndihmuar të zbuloni përvoja të paharrueshme në të gjithë
          vendin.
        </p>
      </div>
    </div>
    <div id="first" className="container pb-4">
      <div className="row">
        <div className="col">
          <ul className="nav d-flex flex-wrap gap-3 justify-content-center sort-source sort-source-style-3">
            <li data-option-value="*" className="active">
              <a className="text-6 font-weight-semi-bold text-color-dark text-hover-primary text-decoration-none">
                Lista e hoteleve më të mira me kazino në Shqipëri
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row pb-5">
      <div className="col">
        <div className="sort-destination-loader pt-2 sort-destination-loader-loaded">
          <div
            className="row portfolio-list py-3 sort-destination"
            data-sort-id="portfolio"
            data-filter="*"
            style={{ position: "relative", height: "100%" }}
          >
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/1646019793_WxH.webp"
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.booking.com/hotel/al/mak-albania-hotel.en-gb.html"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                      Mak Albania Hotel
                    </a>
                  </h4>
                  <p>Sheshi Italia, 1000 Tiranë, Shqipëri</p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion" style={{ height: 156 }}>
                    <p>
                      Hoteli me pesë yje Mak Albania ndodhet në një kompleks
                      tregtar dhe argëtues në zemër të Tiranës. Një qendër
                      fitnesi, 2 pishina, një piano bar dhe një restorant janë
                      në dispozicion të mysafirëve.
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.booking.com/hotel/al/mak-albania-hotel.en-gb.html"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezervo tani
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/marinaAlbania.webp"
                    style={{ height: 268, objectFit: "cover" }}
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.booking.com/hotel/al/marina-bay-luxury-resort-amp-spa.en-gb.html"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                      Maritim Marina Bay Resort &amp; Casino
                    </a>
                  </h4>
                  <p>Rruga Aleksandër Moisiu, 9402 Vlorë, Shqipëri</p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion" style={{ height: 156 }}>
                    <p>
                      I vendosur në Vlorë, 700 metra nga Plazhi Liro, Maritim
                      Marina Bay Resort &amp; Casino ofron akomodim me një
                      pishinë të jashtme sezonale, parkim privat falas, një
                      kopsht dhe një zonë plazhi privat.
                    </p>
                  </div>
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.booking.com/hotel/al/marina-bay-luxury-resort-amp-spa.en-gb.html"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezervo tani
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/286800064.jpg"
                    style={{ height: 268, objectFit: "cover" }}
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.booking.com/hotel/al/lavoglia-boutique.en-gb.html"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                      La Voglia Hotel Boutique
                    </a>
                  </h4>
                  <p>Rruga Reshit Çollaku 5, 1001 Tiranë, Shqipëri</p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion" style={{ height: 156 }}>
                    <p>
                      I vendosur në Tiranë dhe me Sheshin Skënderbej të
                      arritshëm brenda 100 metrave, La Voglia Hotel Boutique
                      ofron check-in dhe check-out të shpejtë, dhoma pa
                      alergjenë, një restorant, WiFi falas dhe një bar.
                    </p>
                  </div>
                  <br />
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.booking.com/hotel/al/lavoglia-boutique.en-gb.html"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezervo tani
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/371798891.jpg"
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.booking.com/hotel/al/radisson-collection-morina-tirana.html"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                      Radisson Collection Morina Hotel, Tirana
                    </a>
                  </h4>
                  <p>Rruga Hamdi Garunja, Tiranë, Shqipëri</p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion" style={{ height: 156 }}>
                    <p>
                      I vendosur në Tiranë, 3.7 kilometra nga Sheshi Skënderbej,
                      Radisson Collection Morina Hotel, Tirana ofron akomodim me
                      një qendër fitnesi, parkim privat falas, një kopsht dhe
                      një tarracë.
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.booking.com/hotel/al/radisson-collection-morina-tirana.html"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezervo tani
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/589628744.jpg"
                    style={{ height: 268, objectFit: "cover" }}
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.booking.com/hotel/al/park.html"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                      Park Hotel Tirana
                    </a>
                  </h4>
                  <p>
                    Rr. Aleksandri i Madh, Pallati Y&amp;S Park Hotel, Tiranë,
                    1001 Tiranë, Shqipëri
                  </p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion" style={{ height: 156 }}>
                    <p>
                      I vendosur në Tiranë, 4.8 km nga Sheshi Skënderbej, Park
                      Hotel Tirana ofron akomodim me biçikleta falas, parkim
                      privat falas, një kopsht dhe një sallë të përbashkët.
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.booking.com/hotel/al/park.html"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezervo tani
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pb-3 isotope-item">
              <div className="card shadow rounded-0">
                <div className="card-img-wrapper p-relative">
                  <img
                    className="card-img-top rounded-0"
                    src="/files/219976390.jpg"
                  />
                </div>
                <div className="card-body p-3">
                  <h4 className="card-title mb-1 text-4 font-weight-bold">
                    <a
                      href="https://www.booking.com/hotel/al/sun-tower-apartment-2019.html"
                      className="text-color-dark text-color-hover-primary text-decoration-none"
                    >
                      Sun Tower Center
                    </a>
                  </h4>
                  <p>Rruga e Kavajës, Tiranë, 1000 Tiranë, Shqipëri</p>
                  <div className="divider divider-small divider-primary">
                    <hr />
                  </div>
                  <div data-mh="promotion" style={{ height: 156 }}>
                    <p>
                      I vendosur në Tiranë, 6 km nga Dajti Ekspres dhe 1 km nga
                      Rezidenca e Dikurshme e Enver Hoxhës, SUN TOWER-CENTER of
                      TIRANA ofron akomodim me WiFi falas dhe parkim privat.
                      Ndodhet 110 metra nga Sheshi Skënderbej.
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="card-body-footer d-flex gap-2">
                    <a
                      href="https://www.booking.com/hotel/al/sun-tower-apartment-2019.html"
                      className="btn btn-outline-primary rounded-0 flex-grow-1"
                    >
                      Rezervo tani
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bounce-loader">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    <Footer />
  </>
);

export default Home;
