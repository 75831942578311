// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => (
  <>
    <Header />
    <div id="main" role="main" className="main">
  <section
    className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-5 mb-0"
    style={{
      backgroundImage: 'url("/files/bg1.webp")',
      backgroundPosition: "center center"
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-12 align-self-center p-static order-2 text-center">
          <h1 className="text-8 font-weight-semi-bold text-uppercase">
            Kushtet e Përdorimit
          </h1>
        </div>
      </div>
    </div>
  </section>
  <div className="container py-5 my-3">
    <div className="col-12 mb-5">
      <div className="d-flex flex-wrap justify-content-between mb-3">
        <h2 className="mb-0" style={{ fontWeight: 600 }} />
      </div>
      <div className="children-img-fluid">
        <p>
          Mirë se vini në Live by Travels! Këto Kushte të Përdorimit rregullojnë
          përdorimin e faqes tonë të internetit dhe shërbimeve që ofrojmë. Duke
          përdorur faqen tonë, ju pranoni dhe pajtoheni të respektoni këto
          kushte. Ju lutemi lexoni me kujdes para se të vazhdoni të përdorni
          faqen tonë.
        </p>
        <h2>1. Përdorimi i Faqes sonë të Internetit</h2>
        <p>
          Live by Travels ofron informacion mbi hotelet dhe kazinot në Shqipëri
          për qëllime informative dhe promovuese. Ju nuk duhet ta përdorni faqen
          për qëllime të tjera komerciale ose për të promovuar shërbime të
          palëve të treta pa lejen tonë paraprake.
        </p>
        <h2>2. Pronësia Intelektuale</h2>
        <p>
          Përmbajtja e kësaj faqeje, përfshirë tekstet, imazhet, grafikat dhe
          logot, është pronë e Live by Travels dhe është e mbrojtur nga ligjet e
          pronësisë intelektuale. Ju nuk keni të drejtë të kopjoni, riprodhoni
          ose shpërndani ndonjë përmbajtje pa leje të shkruar nga ne.
        </p>
        <h2>3. Përgjegjësia për Informacionin</h2>
        <p>
          Live by Travels synon të ofrojë informacion të saktë dhe të
          përditësuar. Megjithatë, ne nuk japim asnjë garanci për saktësinë e
          informacionit dhe nuk marrim përgjegjësi për ndonjë dëm që mund të
          shkaktohet nga përdorimi i këtij informacioni. Ju jeni përgjegjës për
          çdo vendim që merrni bazuar në informacionin e dhënë në këtë faqe.
        </p>
        <h2>4. Lidhjet me Faqet e Palëve të Treta</h2>
        <p>
          Faqja jonë mund të përmbajë lidhje drejt faqeve të palëve të treta.
          Këto lidhje ofrohen për lehtësinë tuaj, por ne nuk marrim përgjegjësi
          për përmbajtjen ose praktikat e privatësisë së faqeve të tjera. Ju
          inkurajojmë të lexoni kushtet e përdorimit dhe politikat e privatësisë
          të këtyre faqeve.
        </p>
        <h2>5. Ndryshimet në Kushtet e Përdorimit</h2>
        <p>
          Ne rezervojmë të drejtën për të ndryshuar këto Kushte të Përdorimit në
          çdo kohë. Çdo ndryshim do të publikohet në këtë faqe dhe data e
          përditësimit do të reflektohet në krye të dokumentit. Ju rekomandojmë
          të kontrolloni periodikisht këtë faqe për përditësime.
        </p>
        <h2>6. Kontakt</h2>
        <p>
          Nëse keni ndonjë pyetje ose kërkesë në lidhje me këto Kushte të
          Përdorimit, ju lutemi na kontaktoni në:
        </p>
        <p>
          Email:{" "}
          <a href="mailto:support@livebytravels.com">
            support@livebytravels.com
          </a>
        </p>
      </div>
    </div>
  </div>
</div>

    <Footer />
  </>
);

export default Home;
